body {
  background-color: white;
  color: black;
  margin: 0;
  /* font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Poppins', sans-serif;
  
}

code {
  font-family: 'Poppins',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px #2e4f4f inset !important;
    -webkit-text-fill-color: "white",
} */